
:root, [data-amplify-theme] {
  /* 
  --amplify-fonts-default-static: "Inter", -apple-system, BlinkMacSystemFont, "Helvetica Neue",
        "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", sans-serif;
  --amplify-fonts-default-variable: "InterVariable", "Inter var", "Inter", -apple-system, BlinkMacSystemFont,
        "Helvetica Neue", "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans",
        sans-serif;
  */    
  --amplify-components-button-color: blue !important;
  
  /*
  --amplify-font-family: "Inter", -apple-system, BlinkMacSystemFont, "Helvetica Neue",
        "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", sans-serif;
  */
}

/* 
[data-amplify-authenticator] {
  --amplify-colors-background-primary: var(--amplify-colors-neutral-90);
  --amplify-colors-background-secondary: var(--amplify-colors-neutral-100);
  --amplify-colors-brand-primary-10: var(--amplify-colors-teal-100);
  --amplify-colors-brand-primary-80: var(--amplify-colors-teal-40);
  --amplify-colors-brand-primary-90: var(--amplify-colors-teal-20);
  --amplify-colors-brand-primary-100: var(--amplify-colors-teal-10);
  --amplify-colors-font-interactive: var(--amplify-colors-white);
  --amplify-components-tabs-item-active-color: var(--amplify-colors-white);
  --amplify-components-tabs-item-focus-color: var(--amplify-colors-white);
  --amplify-components-tabs-item-hover-color: var(--amplify-colors-orange);
}
*/

/* */
.amplify-tabs {
  display: none;
}


/*
html {
  font-family: var(--amplify-fonts-default-static);
}

@supports (font-variation-settings: normal) {
  html {
    font-family: var(--amplify-fonts-default-variable);
  }
}
*/
#root {
  text-align: center;
  margin: 1.2rem;
}

/*nav {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  gap: 2rem;*/
/*  margin-bottom: 2rem;*/
/*}*/

.auth-wrapper {
  display: flex;
  justify-content: center;
  /*margin-top: 2rem;*/
  margin: 1.2rem;
}

.WrappedContent {
  display: flex;
  justify-content: center;
  align-items: center;
  /* */
  width: 100%;
  height: auto;
}

.AppContainerContent {
  background: transparent;
  color: white;
}

.HeaderImage {
  height: 12em;
}

/*.HeaderImageInfo {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/

/*.ANNYVirtualVenue180Maiden {*/
/*  max-width: 1200px;*/
/*}*/

/*h1, h2, h5, h4, h8, h6, p {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  padding-bottom: 1em;*/
/*}*/

.FooterInfo{
  font-size: small;
  margin-top: 2rem;
}

.footernav{
  font-size: small;
  margin-top: 2rem;
}

/*.MenuContent {*/
/*  display: flex;*/
/*  justify-content: flex-end;*/
/*  align-items: center;*/
/*}*/

.AuthContent {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/*.LogoInfo {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/

/*.ANNYVirtualEventsLogo {*/
/*  max-width: 1200px;*/
/*}*/

/*.PosterInfo {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  padding-bottom: 2em;*/
/*}*/

/*.screeningPoster {*/
/*  max-width: 1200px;*/
/*}*/

.ButtonContent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
  padding-bottom: 2em;
}

/*.CastNCarry {*/
/*  text-align: center;*/
/*  margin: auto;*/
/*}*/

/*.CastWrapper {*/
/*  padding-top: 2em;*/
/*  padding-bottom: 2em;*/
/*}*/

/**PLAYER**/
.MediaRow {
  display:flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4em;
}

.MediaContainer {
  display: flex;
  /*max-width: 1200px;*/
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

.MediaWrapper {
  position: absolute;
  position: fixed;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/*.JMPPlayer {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/

/***PLAYLIST***/

/*.PlaylistRow {*/
/*  display:flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  padding-bottom: 4em;*/
  /*extra padding for scroll*/
/*  padding-top: 2em;*/
/*}*/

/*.PlaylistContainer {*/
/*  max-width: 1200px;*/
  /*height: 100%;*/
/*  max-height: 8em;*/
/*  overflow-y: scroll;*/
  /*scrolling*/
/*  position:relative;*/
/*}*/

/*.PlaylistAlign {*/
/*  text-align: center;*/
/*  margin: auto;*/
/*}*/

.VotingRow {
  padding-top: 4em;
}

.ANNYBot {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2em;
}

/*.PrintProgram {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/

.ProgramInfo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeMessage {
  text-align: center;
  padding: 2em;
}

/*.OfflineInfo {*/
/*  padding-top: 4em;*/
/*}*/

.LogoutButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
  padding-bottom: 2em;
}

.ChatAppContent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
  padding-bottom: 2em;
  background-color: black;
}

/*-----*/

/*
.layout-content {
  margin: 0px;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  width: 100%;
  font-size: calc(8px + 2vmin);
  color: #fff;
  background-color: #000;
}

.layout-content a {
  color: #8fa7f0;
}
*/

/* authenticator sign out button */
/*.AmplifySignOut {*/
/*  padding: 4rem 1.5rem;*/
/*    max-width: 200px;*/
/*}*/

/*
.messaging-content {
   max-width: 400px;
   padding: 4rem 1.5rem;
}

.player-content {
  background-color: #000;
}

.VidApp {
  background-color: #000;
}

.VotingApp {
  max-width: 400px;
  padding: 4rem 1.5rem;
  color: #696969;
}
*/

/*amplify-authenticator*/
/*AuthStateApp*/

/*
.MessagingApp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
*/

/*Greeting & Messaging*/
/*.MessagingApp .Greeting {*/
/*    max-width: 400px;*/
/*    padding: 4rem 1.5rem;*/
/*}*/

/*.MessagingApp .Greeting a {*/
/*    color: #8fa7f0;*/
/*    font-weight: bold;*/
/*}*/

/*amplify-authenticator*/
/*.Greeting {*/
/*  color: #696969;*/
/*}*/

/*amplify-authenticator*/
/*#root {*/
/*  text-align: center;*/
/*  --amplify-primary-color: #0000CC;*/
/*  --amplify-primary-tint: #2752d1;*/
/*  --amplify-primary-shade: #0033FF;*/
/*  --amplify-primary-contrast:	var(–amplify-white);*/
/*  --amplify-font-family: Roboto;*/
/*}*/

/*#root {*/
/*  --amplify-colors-background-primary: var(--amplify-colors-dark-90);*/
/*  --amplify-colors-background-secondary: var(--amplify-colors-neutral-100);*/
/*  --amplify-colors-brand-primary-10: var(--amplify-colors-blue-100);*/
/*  --amplify-colors-brand-primary-80: var(--amplify-colors-blue-40);*/
/*  --amplify-colors-brand-primary-90: var(--amplify-colors-blue-20);*/
/*  --amplify-colors-brand-primary-100: var(--amplify-colors-blue-10);*/
/*  --amplify-colors-font-interactive: var(--amplify-colors-white);*/
/*  --amplify-components-tabs-item-active-color: var(--amplify-colors-white);*/
/*  --amplify-components-tabs-item-focus-color: var(--amplify-colors-white);*/
/*  --amplify-components-tabs-item-hover-color: var(--amplify-colors-blue);*/
/*  --amplify-components-field-font-size:	var(--amplify-font-sizes-medium);*/
/*  text-align: center;*/
/*}*/

/*nav {*/
/*  display: flex;*/
/*  display: inline-block;*/
/*  justify-content: center;*/
/*  gap: 2rem;*/
/*  margin-bottom: 2rem;*/
  /*text-align: center;*/
/*}*/


.auth-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 0rem;
}

.bgLogo {
  height: 100vh;
  background-color: #000;
  background-image: url("https://s3.amazonaws.com/animationnights.media/images/loginbg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bgRoom {
  height: 100vh;
  background-color: #000;
  background-image: url("https://s3.amazonaws.com/animationnights.media/images/screeningroom-desktop.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/*.InstructionsLink {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  padding-bottom: 2em;*/
/*}*/

/*amplify-authenticator {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  flex: 1;*/
/*  padding: 4rem 1.5rem;*/
/*  background-color: transparent;*/
/*}*/

/*
@media (prefers-color-scheme: dark) {
  [data-amplify-theme='my-theme'] {
    --amplify-colors-black: #fff;
    --amplify-colors-white: #fff;
  }
}

[data-amplify-theme='my-theme'].disco {
  --amplify-colors-font-primary: pink;
}
*/


.Authenticator {
  display: flex;
  text-align: left;
  flex: 1;
  /*padding: 1rem 1.5rem;*/
  background-color: transparent;
  font-family: 
  'Raleway', 
  'Open Sans', 
  'Inter',
  'sans-serif'
}

.amplify-button[data-variation='primary'] {
  background: linear-gradient(
    to right,
    var(--amplify-colors-blue-80),
    var(--amplify-colors-blue-80)
  );
}


.signOutButton{
  background-color: #2752d1;
  color: #FFF;
  padding: .5em;
  border-bottom-width: thin;
  border-color: #0033FF;
}

.PosterImage{
  background-color: white;
  padding: .1em;
}


/*.standAloneButton{*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  flex: 1;*/
/*  padding: 1rem 1.5rem;*/
/*}*/

.eventDate{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  flex: 1;
  padding: 3rem 1rem;
}

.loginStatusMessage{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: black;
  flex: 1;
  padding: 1rem 1.5rem;
}

ul {
  list-style-type: none;
}

.navLogoImg {
  /*padding-left: 2em;*/
  margin-left: .5em;
}

.customMessage{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  flex: 1;
  padding: 1rem 1rem;
}

/* styles.css */
/*.amplify-text {*/
/*  --amplify-components-text-color-primary: var(--amplify-colors-red-80);*/
/*}*/

/* VotingApp */
.card-text {
 color:black!important; 
}
.card-title {
 color:black!important; 
}
.card-footer {
 color:black!important; 
}

.ImgOverlay {
  position: absolute;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  /*max-height: 100%;*/
  padding: .5em;
}

.cardContainer {
  padding: 0em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.card {
  align-items: center;
}

.card-img-top {
 margin-bottom: .5em;
}
 .voting-buttons {
   padding: .5em;
 }
 
 .TitleContent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
  padding-bottom: 2em;
 }

.headerText .list-group-item {
background-color: transparent;
border-top: 1px solid #ddd;
border-radius: 0;
color: #fff;
}

.cardContainerStacked {
  padding: 0em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.cardStacked {
  border-top:1px solid grey;
  align-items: center;
  padding: 0em;
  margin-top: 1em;
  margin-bottom: 3em;
}


/*
=============== 
Slider
===============
*/
.title {
  text-align: center;
  margin-bottom: 2rem;
}
.title h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}
.title span {
  font-size: 0.85em;
  color: var(--clr-primary-5);
  margin-right: 1rem;
  font-weight: 700;
}
.section-center {
  margin: 0 auto;
  margin-top: 4rem;
  width: 80vw;
  /*
  height: 450px;
  */
  height: 500px;
  max-width: 800px;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
}
.person-img {
  border-radius: 50%;
  margin-bottom: 1rem;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 4px solid var(--clr-grey-8);
  box-shadow: var(--dark-shadow);
}
article h4 {
  text-transform: uppercase;
  color: var(--clr-primary-5);
  margin-bottom: 0.25rem;
}
.title {
  text-transform: capitalize;
  margin-bottom: 0.75rem;
  color: var(--clr-grey-3);
}
.text {
  max-width: 35em;
  margin: 0 auto;
  margin-top: 2rem;
  line-height: 2;
  color: var(--clr-grey-5);
}
.icon {
  font-size: 3rem;
  margin-top: 1rem;
  color: var(--clr-primary-5);
}
.prev,
.next {
  position: absolute;
  top: 200px;
  transform: translateY(-50%);
  background: var(--clr-grey-5);
  color: var(--clr-white);
  width: 1.25rem;
  height: 1.25rem;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 1rem;
  border-radius: var(--radius);
  cursor: pointer;
  transition: var(--transition);
}
.prev:hover,
.next:hover {
  background: var(--clr-primary-5);
}
.prev {
  left: 0;
}
.next {
  right: 0;
}
@media (min-width: 800px) {
  .text {
    max-width: 45em;
  }
  .prev,
  .next {
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
  }
}
article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: var(--transition);
}
article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}
article.lastSlide {
  transform: translateX(-100%);
}
article.nextSlide {
  transform: translateX(100%);
}

/*
=============== 
Table
===============
*/
.projectList {
  text-transform: capitalize;
  font-size: .9rem;
  font-weight: 100;
  font-family: 
  'Raleway', 
  'Open Sans', 
  'Inter',
  'sans-serif'
}

.projectListTitle {
  text-transform: uppercase;
  font-size: 1.3rem;
}

.projectListProjectTitle {
  text-transform: uppercase;
  font-size: .9rem;
}

/*
.videoMedia {
  justify-content: center;
  align-items: center;
}
*/

.video-js-responsive-container.vjs-hd {
    /*padding-top: 56.25%;*/
    padding-top: 0;
}
.video-js-responsive-container.vjs-sd {
    /*padding-top: 75%;*/
    padding-top: 0;
}
.video-js-responsive-container {
    width: 100%;
    position: relative;
}
.video-js-responsive-container .video-js {
    height: 100% !important; 
    width: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
}