/*.btn-space {*/
/*    margin-right: 5px;*/
/*}*/

/* Modify the background color */
         
/*.navbar-custom {*/
/*    background-color: #3065f4;*/
/*}*/
        
/* Modify brand and text color */
         
/*.navbar-custom .navbar-brand,*/
/*.navbar-custom .navbar-text {*/
/*    color: #3065f4;*/
/*}      */

 
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: black;
}

/*.navbar__link {*/
/*  margin: 1em;*/
/*  font-size: 2em;*/
/*  font-family: 'Roboto', sans-serif;*/
/*  cursor: pointer;*/
/*  text-decoration: none;*/
/*  letter-spacing: .3em;*/
/*  text-indent: .3em;*/
/*  color: #181818;*/
/*  border-bottom: 3px solid transparent;*/
/*}*/

/*.navbar__link:hover {*/
/*  transform: translateY(-2px);*/
/*}*/

/*.navbar__link--active {*/
/*  border-bottom: 3px solid #3065f4;*/
/*  transition: border-bottom .5s ease-in-out;*/
/*}*/

/*ul {*/
/*    list-style: none;*/
/*    display: flex;*/
/*    justify-content: space-around;*/
/*}*/
/*ul li a{*/
/*    text-decoration: none;*/
/*    background-color: #000;*/
/*    color: #fff;*/
/*    padding: 10px 20px;*/
/*    font-weight: bold;*/
/*}*/

/*ul li a:hover{*/
/*    background-color: #3065f4;*/
/*    color: #fff;*/
/*}*/

/*.active{*/
/*    background-color: #3065f4;*/
/*    color: #fff;*/
/*}*/


.navButton{
   width: fit-content !important;
   padding: 10px 20px !important;
   text-decoration: none !important;
   background-color: #000 !important;
   color: #fff !important;
   gap: 1rem;
   margin-bottom: .5rem !important;
   margin-right: .5rem !important;
   border: 1px solid #3065f4 !important;
}

.navButton:hover{
   text-decoration: none !important;
   background-color: #3065f4 !important;
   color: #fff !important;
   border: 1px solid #3065f4 !important;
}

.navButton:clicked{
    border: 1px solid #3065f4 !important;
}

.footernav{
  text-align:center;
  margin:0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: black;
}

.navButtonLog{
   width: fit-content !important;
   padding: 10px 20px !important;
   text-decoration: none !important;
   background-color: #3065f4 !important;
   color: #fff !important;
   gap: 1rem;
   margin-bottom: .5rem !important;
   margin-right: .5rem !important;
   border: 1px solid #3065f4 !important;
}

.navButtonLog:hover{
   text-decoration: none !important;
   background-color: #3065f4 !important;
   color: #fff !important;
   border: 1px solid #3065f4 !important;
}

.navButtonLog:clicked{
    border: 1px solid #3065f4 !important;
}

/* styles.css */
/*.amplify-text {*/
/*  --amplify-components-text-color-primary: var(--amplify-colors-red-80);*/
/*}*/