/*.WrappedContent {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/

/*.AppContainerContent {*/
/*  background: black;*/
/*  color: white;*/
/*}*/

/*.HeaderImage {*/
/*  height: 12em;*/
/*}*/

/*.HeaderImageInfo {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/

/*.ANNYVirtualVenue180Maiden {*/
/*  max-width: 1200px;*/
/*}*/

/*h1, h2, h5, h4, h8, h6, p {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/

/*.MenuContent {*/
/*  display: flex;*/
/*  justify-content: flex-end;*/
/*  align-items: center;*/
/*}*/

/*.AuthContent {*/
/*  display: flex;*/
/*  justify-content: flex-end;*/
/*  align-items: center;*/
/*}*/

/*.LogoInfo {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/

/*.ANNYVirtualEventsLogo {*/
/*  max-width: 1200px;*/
/*}*/

/*.PosterInfo {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  padding-bottom: 2em;*/
/*}*/

/*.screeningPoster {*/
/*  max-width: 1200px;*/
/*}*/

/*.ButtonContent {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  padding-top: 2em;*/
/*  padding-bottom: 2em;*/
/*}*/

.CastNCarry {
  text-align: center;
  margin: auto;
  /*override other styles to make responsive */
  width: 100%    !important;
  height: auto   !important;
}

/*
.CastWrapper {
  padding-top: 2em;
  padding-bottom: 2em;
}
*/

/***PLAYER***/
/*.MediaRow {*/
/*  display:flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  padding-bottom: 2em;*/
/*}*/

/*.MediaContainer {*/
/*  display: flex;*/
/*  max-width: 1200px;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  position: relative;*/
/*}*/

/*.MediaWrapper {*/
  /*position: absolute;*/
  /*position: fixed;*/
/*  position: relative;*/
/*  top: 0;*/
/*  right: 0;*/
/*  bottom: 0;*/
/*  left: 0;*/
/*}*/

/*.JMPPlayer {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/

/***PLAYLIST***/

/*.PlaylistRow {*/
/*  display:flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  padding-bottom: 4em;*/
/*}*/

/*.PlaylistContainer {*/
/*  max-width: 1200px;*/
/*  height: 100%;*/
/*  position:relative;*/
/*}*/

/*.PlaylistAlign {*/
/*  text-align: center;*/
/*  margin: auto;*/
/*}*/

/*.VotingRow {*/
/*  padding-top: 6em;*/
/*}*/

/*.ANNYBot {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  padding-bottom: 2em;*/
/*}*/

/*.PrintProgram {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/

/*.LogoutButtonContainer {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  padding-top: 2em;*/
/*  padding-bottom: 2em;*/
/*}*/

/*-----*/

/*
.layout-content {
  margin: 0px;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  width: 100%;
  font-size: calc(8px + 2vmin);
  color: #fff;
  background-color: #000;
}

.layout-content a {
  color: #8fa7f0;
}
*/

/* authenticator sign out button */
/*.AmplifySignOut {*/
/*  padding: 4rem 1.5rem;*/
/*    max-width: 200px;*/
/*}*/

/*
.messaging-content {
   max-width: 400px;
   padding: 4rem 1.5rem;
}

.player-content {
  background-color: #000;
}

.VidApp {
  background-color: #000;
}

.VotingApp {
  max-width: 400px;
  padding: 4rem 1.5rem;
  color: #696969;
}
*/

/*amplify-authenticator*/
/*AuthStateApp*/

/*
.MessagingApp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
*/

/*Greeting & Messaging*/
/*.MessagingApp .Greeting {*/
/*    max-width: 400px;*/
/*    padding: 4rem 1.5rem;*/
/*}*/

/*.MessagingApp .Greeting a {*/
/*    color: #8fa7f0;*/
/*    font-weight: bold;*/
/*}*/

/*amplify-authenticator*/
/*.Greeting {*/
/*  color: #696969;*/
/*}*/

/*Amplify React UI Component override*/
/*:root {*/
/*  --amplify-primary-color: #0000CC;*/
/*  --amplify-primary-tint: #2752d1;*/
/*  --amplify-primary-shade: #0033FF;*/
/*  --amplify-primary-contrast:	var(–amplify-white);*/
/*}*/

/*.bgLogo {*/
/*  height: 100vh;*/
/*  background-color: #000;*/
/*  background-image: url("https://s3.amazonaws.com/animationnights.media/images/loginbg.jpg");*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*  background-position: center;*/
/*}*/

/*.InstructionsLink {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  padding-bottom: 2em;*/
/*}*/

/*amplify-authenticator {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  flex: 1;*/
/*  padding: 4rem 1.5rem;*/
/*}*/
